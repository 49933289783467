// ? ---
// ?	Imports
// ? ---
import * as React from 'react'
import debug from 'debug'

import Head from 'next/head'

import E403 from 'components/_Global/E403'

// ? ---
// ?	Constants
// ? ---
const namespace = 'views-index-view'
const log = debug(`app:${namespace}`)

// ? ---
// ?	View
// ? ---
export default function View(): JSX.Element {
	// * ---
	// *	Setup
	// * ---
	log('.')

	// * ---
	// *	Return: View
	// * ---
	return (
		<>
			<Head>
				<title>DoesQA</title>
				<meta name='robots' content='noindex, nofollow' />
			</Head>
			<E403 />
		</>
	)
}
