// ? ---
// ?	Imports
// ? ---
import Props from 'views/landing/props'
import View from 'views/landing/view'

// ? ---
// ?	Export
// ? ---
export { Props as getStaticProps }
export default View
